<template>
    <breadcrumb btnText="返回列表" btnIcon="n-direction-left" @btn-click="goBack"></breadcrumb>
    <div class="page-user-edit">
        <user-form 
            title="编辑用户" 
            v-model:data="data"
        ></user-form>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import UserForm from '@/components/forms/UserForm.vue';

@Options({
    components: {
        Breadcrumb, UserForm
    },
    data(){
        return{
            data: {}
        }
    },
    mounted(){
        this.getInfo(Number(this.$route.params.id))
    },
    methods: {
        goBack(){
            this.$router.go(-1);
        },
        getInfo(id: number){
            this.$api.getUserInfoById(id).then((res: any) => {
                this.data = res.data
            })
        }
    }
})

export default class UserCreate extends Vue {}
</script>